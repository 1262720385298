<template>
  <div class="product-details">
    <el-card shadow="hover" :body-style="{ padding: '20px' }">
      <div>
        <h1>Product Details</h1>
        <div class="btn-group">
          <el-button v-if="!isEditMode" type="primary" @click="saveForm">
            {{ $t("AddProductPageSaveBtn") }}
          </el-button>

          <div v-else>
            <el-button
              v-if="!productDetailsEditable"
              type="primary"
              size="mini"
              @click="productDetailsEditable = true"
            >
              {{ $t("RetailersDEtailsPageBtnEdit") }}
            </el-button>
            <template v-else>
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-close"
                @click="cancelEditMode"
              >
                {{ $t("RetailersDEtailsPageBtnCancel") }}
              </el-button>

              <el-button type="primary" size="mini" @click="saveForm">
                {{ $t("RetailersDEtailsPageBtnSaveData") }}
              </el-button>
            </template>
          </div>
        </div>
      </div>
      <el-form
        :model="productDetails"
        :rules="productDetailsRules"
        :disabled="!productDetailsEditable && isEditMode"
        :ref="productDetailsFormName"
        label-width="150px"
      >
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item :label="$t('AddProductPageNameLabel')" prop="name">
              <el-input
                v-model="productDetails.name"
                :placeholder="$t('AddProductPageEnterProductNamePlaceholder')"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('AddProductPageTrademarkLabel')"
              prop="tradeMark"
            >
              <el-select
                filterable
                v-if="!addTrademark && lookups"
                clearable
                remote
                :remote-method="searchForTrademarks"
                auto-complete="one"
                style="width: 150px"
                v-model="productDetails.tradeMark"
                :placeholder="$t('AddProductPageSelectTrademarkPlaceholder')"
              >
                <el-option
                  v-for="(item, index) in trademarks"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>

              <el-input
                style="width: 150px"
                v-else
                v-model="productDetails.tradeMark"
                :placeholder="
                  $t('AddProductPageAddTrademarkCompanyplaceholder')
                "
              ></el-input>

              <el-button
                @click="changeTrademark()"
                type="text"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--2x'
                    : 'u-left-margin--2x'
                "
              >
                {{
                  addTrademark
                    ? $t("AddProductPageSelectTrademarkBtn")
                    : $t("AddProductPageAddNewTrademarkBtn")
                }}
              </el-button>
            </el-form-item>

            <el-form-item
              :label="$t('AddProductPageManufactureLabel')"
              prop="manufacturer"
            >
              <el-select
                filterable
                v-if="!addManufacture && lookups"
                clearable
                remote
                :remote-method="searchForManufacturer"
                auto-complete="one"
                style="width: 130px"
                width="200px"
                v-model="productDetails.manufacturer"
                :placeholder="$t('AddProductPageSelectManufacturePlaceholder')"
              >
                <el-option
                  v-for="(item, index) in manufacturer"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>

              <el-input
                style="width: 130px"
                v-else
                v-model="productDetails.manufacturer"
                :placeholder="$t('AddProductPageAddManufacturePlaceholeder')"
              ></el-input>

              <el-button
                @click="changeManufacture()"
                type="text"
                :class="
                  $store.state.isRTL
                    ? 'u-right-margin--2x'
                    : 'u-left-margin--2x'
                "
              >
                {{
                  addManufacture
                    ? $t("AddProductPageSelectManufactureBtn")
                    : $t("AddProductPageAddManufactureBtn")
                }}
              </el-button>
            </el-form-item>

            <el-form-item
              :label="$t('AddProductPageCategoryLabel')"
              prop="subcategoryId"
            >
              <el-select
                filterable
                v-if="lookups"
                clearable
                class="large"
                v-model="productDetails.subcategoryId"
                :placeholder="
                  $t('AddProductPageSelectProductCategoryPlaceholder')
                "
              >
                <el-option-group
                  v-for="category in lookups.category"
                  :key="category.name"
                  :label="category.name"
                >
                  <el-option
                    v-for="item in category.subcategories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('AddProductPageProductBasketLabel')"
              size="normal"
            >
              <el-checkbox
                v-model="productDetails.isBasket"
                label=""
                :true-label="1"
                :indeterminate="false"
                @change="resetBasketFields"
              >
              </el-checkbox>
            </el-form-item>

            <el-form-item
              v-if="productDetails.isBasket"
              prop="description.summary"
              :label="$t('AddProductPageSummaryLabel')"
              size="normal"
            >
              <el-input
                v-model="productDetails.description.summary"
                placeholder=""
                size="normal"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="productDetails.isBasket"
              prop="details"
              :label="$t('AddProductPageDetailsLabel')"
              size="normal"
            >
              <div style="position: relative">
                <picker
                  :native="true"
                  :showCategories="true"
                  :showPreview="true"
                  :style="{
                    position: 'absolute',
                    left: ' 35%',
                    top: '-70%',
                    'z-index': '99999',
                    display: emojiPickerShown ? 'flex' : 'none',
                  }"
                  @select="addEmoji($event, markdownEditorRefName)"
                />

                <Editor
                  @load="onEditorLoad($event, 'markdownEditorTitle')"
                  :ref="markdownEditorRefName"
                  :initialValue="productDetails.description.details"
                  initialEditType="wysiwyg"
                  height="130px"
                  :options="editorOptions"
                />
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="13">
            <div class="image-container u-top-margin--2x">
              <el-form-item label="" label-width="0" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="onChangeImage"
                >
                  <img
                    v-if="productDetails.image"
                    :src="productDetails.image"
                    class="avatar"
                  />

                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { constants, productDetailsRules } from "./productComponents.data";
import { mapGetters } from "vuex";
import { Picker } from "emoji-mart-vue";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
function createLastButton(handler) {
  const button = document.createElement("button");
  button.className = "toastui-editor-toolbar-icons last";
  button.style.backgroundImage = "none";
  button.style.margin = "0";
  button.innerHTML = `<i class="el-icon-bicycle"></i>`;
  button.addEventListener("click", (e) => {
    handler();
    e.preventDefault();
  });
  return button;
}
export default {
  name: "ProductDetailsCard",
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
    },
  },
  components: {
    Picker,
    Editor,
  },
  data() {
    return {
      productDetails: {
        image: "",
        description: { summary: "" },
        tradeMark: "",
        manufacturer: "",
      },
      emojiPickerShown: false,
      addTrademark: false,
      addManufacture: false,
      productDetailsFormName: constants.productDetailsFormName,
      editorOptions: {
        minHeight: "200px",
        useDefaultHTMLSanitizer: true,
        hideModeSwitch: true,
        toolbarItems: [["bold", "italic", "strike", "heading"]],
      },
      trademarks: [],
      manufacturer: [],
      markdownEditorRefName: constants.markdownEditorRefName,
      productDetailsRules: productDetailsRules,
      productDetailsEditable: false,
      useCommandShortcut: false,
    };
  },
  mounted() {
    if (this.initialData) {
      const mappedInitialData = {
        name: this.initialData.name,
        tradeMark: this.initialData.tradeMark,
        manufacturer: this.initialData.manufacturer,
        subcategoryId: this.initialData.subcategoryId,
        isBasket: this.initialData.isBasket,
        image: this.initialData.image,
        description: JSON.parse(JSON.stringify(this.initialData.description)),
      };
      this.productDetails = mappedInitialData;
    }
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
  methods: {
    // *********************************************************
    // Basket/Editor Area
    // *********************************************************

    onEditorLoad(editor, target) {
      // To fix a bug in the editor load event called when editor  not fully loaded
      setTimeout(() => {
        editor.insertToolbarItem(
          { groupIndex: 0, itemIndex: 4 },
          {
            el: createLastButton(() => {
              if (target == "markdownEditorTitle") {
                this.emojiPickerShown = !this.emojiPickerShown;
                return;
              }
            }),
            tooltip: "Emoji",
            style: "color:black;",
          }
        );
      }, 0);
    },
    resetBasketFields(isBasket) {
      if (!isBasket) {
        this.productDetails.description = undefined;
        return;
      }
      this.productDetails.description = {};
    },

    addEmoji(emoji, target) {
      this.$refs[target].invoke("insertText", emoji.native);
      this.emojiPickerShown = false;
    },
    // *******************************
    // custom trademark/manufacture Area
    // ******************************
    changeManufacture() {
      this.addManufacture = !this.addManufacture;
      this.productDetails.manufacturer = "";
    },
    changeTrademark() {
      this.addTrademark = !this.addTrademark;
      this.productDetails.tradeMark = "";
    },

    handleChange(e, key) {
      this.productDetails[key] = parseInt(e.target.value);
    },
    searchForTrademarks(query) {
      this.searchForRemoteData(query, "trademarks");
    },
    searchForManufacturer(query) {
      this.searchForRemoteData(query, "manufacturer");
    },
    searchForRemoteData(query, key) {
      if (query) {
        this[key] = this.lookups[key].filter((item) => {
          if (item) {
            return item.toLowerCase().includes(query.toLowerCase());
          }
        });
      } else {
        this[key] = [];
      }
    },

    // *******************************
    // Actions Area
    // ******************************
    async saveForm() {
      let productBasketDetails;
      if (this.productDetails.isBasket) {
        //Must get the value of markdown editor from event not vmodel
        productBasketDetails =
          this.$refs[this.markdownEditorRefName].invoke("getMarkdown");
      }
      const isValid = await this.$refs[this.productDetailsFormName].validate();
      if (isValid) {
        const productObject = JSON.parse(JSON.stringify(this.productDetails));
        productObject.isBasket = productObject.isBasket ? true : false;
        delete productObject.image;
        delete productObject.imageFile;

        if (productObject.isBasket && productBasketDetails) {
          productObject.description.details = productBasketDetails;
        }

        const eventPayload = {
          productObject: productObject,
          imageData: {
            imageUrl: this.productDetails.imageUrl,
            imageFile: this.productDetails.imageFile,
          },
        };

        this.$emit("product-saved", eventPayload);
        this.productDetailsEditable = false;
      }
    },
    cancelEditMode() {
      const mappedInitialData = {
        name: this.initialData.name,
        tradeMark: this.initialData.tradeMark,
        manufacturer: this.initialData.manufacturer,
        subcategoryId: this.initialData.subcategoryId,
        isBasket: this.initialData.isBasket,
        image: this.initialData.image,
        description: JSON.parse(JSON.stringify(this.initialData.description)),
      };
      this.productDetails = mappedInitialData;
      this.productDetailsEditable = false;
    },
    onChangeImage(file) {
      this.productDetails.imageFile = file.raw;
      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.productDetails.image = URL.createObjectURL(file.raw);
          this.dialogVisible = true;
        };
        img.src = evt.target.result;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-card {
  overflow: visible;
}
.product-details {
  max-width: 1200px;
  margin: 0 auto;
}
// upload image folder
.image-container {
  display: flex;
  justify-content: center;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
  }
  .avatar {
    width: 355px;
    height: 355px;
    display: block;
  }
}
</style>
