<template>
  <el-card
    class="unit-details"
    shadow="always"
    style="margin-block: 2rem"
    :body-style="{ padding: '20px' }"
  >
    <div>
      <div>
        <h1>Product Units</h1>
        <div class="btn-group">
          <el-button type="primary" plain size="default" @click="addNewUnit">
            Assign a New Unit
          </el-button>

          <el-button
            v-if="!isEditMode"
            :disabled="!unitsDetails.length"
            type="primary"
            @click="saveForm"
            >{{ $t("AddProductPageSaveBtn") }}</el-button
          >
          <template v-else>
            <el-button
              v-if="!unitDetailsEditable"
              type="primary"
              @click="unitDetailsEditable = true"
            >
              {{ $t("RetailersDEtailsPageBtnEdit") }}
            </el-button>
            <template v-else>
              <el-button
                type="danger"
                icon="el-icon-close"
                @click="cancelEditMode"
              >
                {{ $t("RetailersDEtailsPageBtnCancel") }}</el-button
              >
              <el-button @click="editUnits" type="primary">{{
                $t("RetailersDEtailsPageBtnSaveData")
              }}</el-button>
            </template>
          </template>
        </div>
        <div class="units-wrapper">
          <div
            class="unit-card"
            v-for="(unit, i) in unitsDetails"
            :key="unit.createdAt ? unit.createdAt : unit.unitId"
          >
            <el-card shadow="always" :body-style="{ padding: '20px' }">
              <el-collapse :value="i" :accordion="false">
                <el-collapse-item :name="i">
                  <template slot="title">
                    <div class="unit-header">
                      <h2 v-if="unit.unitId">
                        {{ getUnitNameFromId(unit.unitId) }}
                      </h2>
                      <h2 v-else>Unit{{ i + 1 }}</h2>
                    </div>
                  </template>
                  <div class="unit-actions">
                    <el-button
                      v-if="unitsDetails.length > 1 && !unitDetailsEditable"
                      type="danger"
                      size="default"
                      icon="el-icon-delete"
                      circle=""
                      @click="discardUnit(unit)"
                    ></el-button>
                  </div>
                  <el-form
                    :model="unit"
                    :ref="unitDetailsFormName + i"
                    :disabled="isEditMode && !unitDetailsEditable"
                    :rules="unitDetailsRules"
                    label-width="120px"
                    :inline="false"
                    size="normal"
                  >
                    <el-form-item
                      :label="$t('AddProductPageUnitLabel')"
                      prop="unitId"
                    >
                      <el-select
                        filterable
                        clearable
                        value-key="id"
                        auto-complete="one"
                        style="width: 150px"
                        v-model="unit.unitId"
                        :disabled="isEditMode"
                        :placeholder="$t('AddProductPageSelectUnitPlaceholder')"
                      >
                        <el-option
                          v-for="item in availableUnits(unit.unitId).filter(
                            (u) => u.id != unit.childUnitId
                          )"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-row :gutter="20">
                      <el-col :span="12" :offset="0">
                        <el-form-item
                          :label="
                            $t('AddProductPageAddProductItemPerUnitLabel')
                          "
                          prop="itemsPerUnit"
                        >
                          <el-input-number
                            v-model.number="unit.itemsPerUnit"
                            size="normal"
                            :min="1"
                            :step="1"
                            :controls="false"
                          >
                          </el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col :span="1" :offset="0"><p>of</p></el-col>
                      <el-col :span="11" :offset="0">
                        <el-form-item
                          :label="$t('Child Unit')"
                          prop="childUnitId"
                        >
                          <el-select
                            filterable
                            clearable
                            auto-complete="one"
                            style="width: 150px"
                            v-model="unit.childUnitId"
                            :placeholder="
                              $t('AddProductPageSelectUnitPlaceholder')
                            "
                          >
                            <el-option
                              v-for="item in lookups.unitsMain.filter(
                                (u) => u.id != unit.unitId
                              )"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select> </el-form-item
                      ></el-col>
                    </el-row>

                    <el-form-item
                      :label="$t('AddProductPageAddProductMaxPerOrderLabel')"
                      prop="maximumPerOrder"
                    >
                      <el-input-number
                        v-model.number="unit.maximumPerOrder"
                        size="normal"
                        :min="1"
                        :step="1"
                        :controls="false"
                      >
                      </el-input-number>
                    </el-form-item>

                    <el-form-item
                      :label="$t('AddProductPageDisplayPriceLabel')"
                      prop="displayPrice"
                    >
                      <el-input-number
                        v-model.number="unit.displayPrice"
                        size="normal"
                        :step="1"
                        :controls="false"
                      >
                      </el-input-number>
                    </el-form-item>

                    <el-form-item
                      :label="$t('AddProductPageBarcodeLabel')"
                      prop="barcode"
                    >
                      <el-input
                        v-model="unit.barcode"
                        size="normal"
                        clearable
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Size" prop="itemSize">
                      <el-input v-model="unit.itemSize"> </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('is Sellable')" prop="isSellable">
                      <el-switch
                        v-model="unit.isSellable"
                        :active-value="true"
                        :inactive-value="false"
                      >
                        Is Sellable
                      </el-switch>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-card>
          </div>
        </div>
      </div>
      <el-dialog title="" :visible.sync="unitDialogVisible" width="55%">
        <el-form
          :model="newUnit"
          ref="newUnitForm"
          :rules="unitDetailsRules"
          label-width="120px"
          :inline="false"
          size="normal"
        >
          <el-form-item :label="$t('AddProductPageUnitLabel')" prop="unitId">
            <el-select
              filterable
              clearable
              value-key="id"
              auto-complete="one"
              style="width: 150px"
              v-model="newUnit.unitId"
              :placeholder="$t('AddProductPageSelectUnitPlaceholder')"
            >
              <el-option
                v-for="item in availableUnits(newUnit.unitId).filter(
                  (u) => u.id != newUnit.childUnitId
                )"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('AddProductPageAddProductItemPerUnitLabel')"
                prop="itemsPerUnit"
              >
                <el-input-number
                  v-model.number="newUnit.itemsPerUnit"
                  size="normal"
                  :min="1"
                  :step="1"
                  :controls="false"
                >
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="1" :offset="0"
              ><p style="white-space: no-wrap">of</p></el-col
            >
            <el-col :span="11" :offset="0">
              <el-form-item :label="$t('Child Unit')" prop="childUnitId">
                <el-select
                  filterable
                  clearable
                  auto-complete="one"
                  style="width: 150px"
                  v-model="newUnit.childUnitId"
                  :placeholder="$t('AddProductPageSelectUnitPlaceholder')"
                >
                  <el-option
                    v-for="item in lookups.unitsMain.filter(
                      (u) => u.id != newUnit.unitId
                    )"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select> </el-form-item
            ></el-col>
          </el-row>

          <el-form-item
            :label="$t('AddProductPageAddProductMaxPerOrderLabel')"
            prop="maximumPerOrder"
          >
            <el-input-number
              v-model.number="newUnit.maximumPerOrder"
              size="normal"
              :min="1"
              :step="1"
              :controls="false"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item
            :label="$t('AddProductPageDisplayPriceLabel')"
            prop="displayPrice"
          >
            <el-input-number
              v-model.number="newUnit.displayPrice"
              size="normal"
              :min="1"
              :step="1"
              :controls="false"
            >
            </el-input-number>
          </el-form-item>

          <el-form-item
            :label="$t('AddProductPageBarcodeLabel')"
            prop="barcode"
          >
            <el-input
              v-model="newUnit.barcode"
              size="normal"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="Size" prop="itemSize">
            <el-input v-model="newUnit.itemSize"> </el-input>
          </el-form-item>
          <el-form-item :label="$t('is Sellable')" prop="isSellable">
            <el-switch
              v-model="newUnit.isSellable"
              :active-value="true"
              :inactive-value="false"
            >
              Is Sellable
            </el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="unitDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveNewUnit">Save</el-button>
        </span>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
import { constants, unitDetailsRules } from "./productComponents.data";
import { mapGetters } from "vuex";
import Sortable from "sortablejs";

export default {
  name: "UnitsDetailsCard",
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Array,
    },
  },

  data() {
    return {
      unitsDetails: [{ createdAt: Date.now() }],
      newUnit: {},
      unitDialogVisible: false,
      unitDetailsFormName: constants.productDetailsFormName,
      unitDetailsRules: unitDetailsRules,
      unitDetailsEditable: false,
    };
  },
  mounted() {
    // Initial data logic
    if (this.initialData) {
      this.unitsDetails = JSON.parse(
        JSON.stringify(
          this.initialData
            .sort((a, b) => {
              if (a.displayOrder < b.displayOrder) return -1;
              if (a.displayOrder > b.displayOrder) return 1;
              return 0;
            })
            .map((unit) => {
              return { ...unit, isSellable: unit.isSellable ? true : false };
            })
        )
      );
    }
    const _this = this;
    // to fix DOM is not loading some times in mounted
    setTimeout(() => {
      // Sortable js logic
      const unitCards = document.querySelector(".units-wrapper");
      Sortable.create(unitCards, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.unitsDetails.splice(oldIndex, 1)[0];
          _this.unitsDetails.splice(newIndex, 0, currRow);
        },
      });
    }, 1000);
  },
  watch: {
    initialData: function (newValue) {
      if (newValue) {
        this.unitsDetails = JSON.parse(JSON.stringify(newValue))
          .sort((a, b) => {
            if (a.displayOrder < b.displayOrder) return -1;
            if (a.displayOrder > b.displayOrder) return 1;
            return 0;
          })
          .map((unit) => {
            return { ...unit, isSellable: unit.isSellable ? true : false };
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
  methods: {
    availableUnits(unitId) {
      const currentUnits = this.unitsDetails.map((unit) => unit.unitId);
      if (!this.lookups?.unitsMain) return [];
      if (unitId)
        return this.lookups.unitsMain.filter(
          (unit) => !currentUnits.includes(unit.id) || unit.id === unitId
        );

      return this.lookups.unitsMain.filter(
        (unit) => !currentUnits.includes(unit.id)
      );
    },
    getUnitNameFromId(unitId) {
      const currentUnit = this.lookups.unitsMain.find(
        (unit) => unit.id == unitId
      );
      return currentUnit.name;
    },
    // *******************************
    // Actions Area
    // ******************************
    async saveForm() {
      let isAllValid = true;
      for (let i = 0; i < this.unitsDetails.length; i++) {
        const unitFormName = this.unitDetailsFormName + i;
        try {
          const currentValue = await this.$refs[unitFormName][0].validate();
          isAllValid = isAllValid && currentValue;
        } catch {
          isAllValid = false;
        }
      }

      if (isAllValid) {
        const unitObject = JSON.parse(JSON.stringify(this.unitsDetails));
        const cleanedUnits = unitObject.map((unit, i) => {
          const mappedUnit = unit;
          mappedUnit.displayOrder = i + 1;
          delete mappedUnit.createdAt;
          return mappedUnit;
        });

        this.$emit("units-saved", cleanedUnits);
      }
    },
    discardUnit(unit) {
      if (unit.unitId) {
        this.$emit("unit-deleted", unit);
        return;
      }
      this.unitsDetails = this.unitsDetails.filter(
        (u) => u.createdAt != unit.createdAt
      );
    },
    cancelEditMode() {
      this.$emit("units-edit-canceled");
      this.unitDetailsEditable = false;
      this.unitsDetails = JSON.parse(JSON.stringify(this.initialData));
    },
    addNewUnit() {
      if (this.isEditMode) {
        this.unitDialogVisible = true;
        return;
      }
      this.unitsDetails.push({ createdAt: Date.now() });
    },
    async editUnits() {
      let isAllValid = true;
      for (let i = 0; i < this.unitsDetails.length; i++) {
        const unitFormName = this.unitDetailsFormName + i;
        try {
          const currentValue = await this.$refs[unitFormName][0].validate();
          isAllValid = isAllValid && currentValue;
        } catch {
          isAllValid = false;
        }
      }

      if (isAllValid) {
        const unitObject = JSON.parse(JSON.stringify(this.unitsDetails));
        const cleanedUnits = unitObject.map((unit, i) => {
          const mappedUnit = unit;
          mappedUnit.displayOrder = i + 1;
          delete mappedUnit.createdAt;
          return mappedUnit;
        });

        this.$emit("unit-updated", JSON.parse(JSON.stringify(cleanedUnits)));
        this.unitDetailsEditable = false;
      }
    },
    async saveNewUnit() {
      const valid = await this.$refs["newUnitForm"].validate();
      if (valid) {
        const newUnit = JSON.parse(JSON.stringify(this.newUnit));
        this.$emit("new-unit-saved", newUnit);
        this.unitDialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unit-details {
  max-width: 1200px;
  margin: 0 auto;
}
.el-select {
  width: 100%;
}
.el-collapse {
  border: none;
}
::v-deep .el-collapse-item__wrap {
  border: none !important;
}
::v-deep .el-collapse-item__header {
  border: none !important;
}

.unit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 98%;
}
.unit-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.units-wrapper {
  margin-top: 1rem;
  width: 700px;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 2rem;
}
</style>
