import VueI18n from "../../../i18n";

export const constants = {
  productDetailsFormName: "productDetailsForm",
  markdownEditorRefName: "markdownEditor",
  productDetailsRefName: "productDetailsCard",
};
var englishTextAndNumberOnly = (rule, value, callback) => {
  const validText = /^[a-zA-Z0-9 ]*$/.test(value);
  if (!validText) {
    callback(new Error("Accept Only English characters and numbers"));
  } else {
    callback();
  }
};
export const productDetailsRules = {
  name: [
    {
      required: true,
      message: VueI18n?.t("AddProductPageProductInputProductNameValidation"),
      trigger: "blur",
    },
  ],

  manufacturer: [
    {
      required: true,
      message: VueI18n?.t(
        "AddProductPageProductSelectManufactureCompanyValidation"
      ),
      trigger: "change",
    },
  ],

  subcategoryId: [
    {
      required: true,
      message: VueI18n?.t(
        "AddProductPageProductSelectProductCategoryValidation"
      ),
      trigger: "change",
    },
  ],
  "description.summary": [
    {
      required: true,
      message: VueI18n?.t("AddProductPageProductEnterSummaryValidation"),
      trigger: "change",
    },
  ],
};

export const unitDetailsRules = {
  unitId: [
    {
      required: true,
      message: VueI18n?.t("AddProductPageAddUnitValidation"),
      trigger: "blur",
    },
  ],
  barcode: [
    {
      min: 1,
      max: 20,
      message: VueI18n?.t("AddproductPageEnterBarcodeValidation"),
      trigger: "change",
    },
    { validator: englishTextAndNumberOnly, trigger: "change" },
  ],
  itemsPerUnit: [
    {
      min: 0,
      max: 999999,
      type: "number",
      message: "Items per unit can't  be higher than 6 digits ",
      trigger: "change",
    },
  ],
  maximumPerOrder: [
    {
      min: 0,
      max: 999999,
      type: "number",
      message: "max per order can't  be higher than 6 digits ",
      trigger: "change",
    },
  ],
  displayPrice: [
    {
      min: 0,
      max: 100000,
      type: "number",
      message: "Display Price must be between 0 and 100000",
      trigger: "change",
    },
  ],
};
